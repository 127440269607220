@import 'variables';

.logoHeaderWithNav {
  a,
  span {
    color: white !important;
  }

  z-index: 10;
  
  .link-item {
    margin: 0 20px 0 20px;
    color: white !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #header {
    &LogoCol {
      margin-right: 2em;

      img {
        width: clamp(80px, 15vw, 15vw);
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &Links {
      .outerLinkContainer {
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: $md) {
          max-width: 12vw;
        }
        .innerLinkContainer {
          text-align: center;
        }
      }
    }
  }

  .nodropdown {
    .dropdown-menu {
      display: none !important;
    }
  }

  .headerLinksText {
    white-space: normal;
  }

  .dropdown-toggle::after {
    content: none !important;
  }

  .modal-body {
    text-align: center;
  }
}

#header {
  &Container {
    padding: clamp(1px, 1vw, 10px);
    padding: 15px;
  }

  &Title {
    font-size: 3.5vw;
    margin-bottom: 0;
  }

  &TitleCol,
  &LogoCol {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.App-logo {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
}

#portalNav {
  font-family: GothamBlack;
  font-weight: bold;

  .dropdown-item {
    font-family: Gotham !important;
  }

  .nav-link {
    margin: 0 20px 0 20px;
  }
}

#forcelinkFooter {
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  .navbar-brand {
    height: 8vh;
    pointer-events: all;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

#ServestISApp {
  
  #headerContainer {
    padding: 16px;
  }
}

#ServestITApp,
#ServestISApp,
#BedworthApp,
#KslApp,
#JoshcoApp {
  #forcelinkFooter {
    @media screen and (max-width: $md) {
      background-color: #f8f9fa !important;
    }
  }

  #headerLinks.showDropdown {
    background-color: adjust-color($color: $ServestITColor, $lightness: 5%);
  }

  .logoHeaderWithNav,
  #headerContainer {
    justify-content: left;
    background-color: $ServestITColor;
  }
}

#ServestISApp {
  .logoHeaderWithNav {
    justify-content: left;
    background-color: #131e98;
    color: #ffffff;
    height: 60px;
    border: 1px solid var(--border-f, #131e98);

    #headerLogoCol img {
      width: clamp(100px, 100px, 100px);
    }

    a,
    .logoHeaderWithNav span {
      color: #ffffff !important;

      svg {
        margin-right: 8px;
      }
    }

    .navbar-text {
      color: #ffffff !important;
    }

    .innerLinkContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

#KslApp,
#JoshcoApp {
  .logoHeaderWithNav {
    justify-content: left;
    background-color: #ffffff;
    color: #445666;
    height: 60px;
    border: 1px solid var(--border-f, #ebebeb);

    #headerLogoCol img {
      width: clamp(100px, 100px, 100px);
    }

    a,
    .logoHeaderWithNav span {
      color: #445666 !important;

      svg {
        margin-right: 8px;
      }
    }

    .navbar-text {
      color: #445666 !important;
    }

    .innerLinkContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}

#SyntellApp {
  .logoHeaderWithNav {
    a,
    span {
      color: white !important;
    }

    .link-item {
      margin: 0 20px 0 20px;
      color: white !important;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    #header {
      &LogoCol {
        margin-right: 2em;

        img {
          width: 100px;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &Links {
        .outerLinkContainer {
          margin: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (min-width: $md) {
            max-width: 12vw;
          }
          .innerLinkContainer {
            text-align: center;
          }
        }
      }
    }

    .headerLinksText {
      white-space: normal;
    }

    .dropdown-toggle::after {
      content: none !important;
    }

    .modal-body {
      text-align: center;
    }
  }

  #forcelinkFooter {
    .navbar-brand {
      height: 8vh;
      width: 80%;
      pointer-events: all;
    }

    @media screen and (max-width: $md) {
      //background-color: #f8f9fa !important;
      color: black !important;
    }
  }

  #headerLinks.showDropdown {
    background-color: adjust-color($color: #987713, $lightness: 5%);
  }

  .logoHeaderWithNav,
  #headerContainer {
    background-color: rgb(19, 30, 152);
  }
}

#SiemensApp {
  .logoHeaderWithNav,
  #headerContainer {
    background-color: $SiemensColor;
  }
}

#SSGFacilitiesApp {
  .logoHeaderWithNav {
    a,
    span {
      color: #595959 !important;
    }

    flex-wrap: nowrap !important;

    .link-item {
      margin: 0 20px 0 20px;
      color: #595959 !important;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    #header {
      &LogoCol {
        margin-right: 2em;

        img {
          height: 35px;
          width: 42px;
        }
      }

      &Links {
        .outerLinkContainer {
          height: 90%;
          margin-top: -1.25em;
          margin: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: fit-content;
          @media screen and (min-width: $md) {
            max-width: 12vw;
          }
          .innerLinkContainer {
            text-align: center;
          }
        }
      }
    }

    .headerLinksText {
      white-space: normal;
    }

    .dropdown-toggle::after {
      content: none !important;
    }

    .modal-body {
      text-align: center;
    }
  }

  #forcelinkFooter {
    .navbar-brand {
      height: 100%;
    }
    height: 60px;
    @media screen and (max-width: $md) {
      background-color: #f8f9fa !important;
    }
  }

  #headerLinks.showDropdown {
    background-color: adjust-color($color: $SSGFacilitiesColor, $lightness: 5%);
  }

  .logoHeaderWithNav,
  #headerContainer {
    width: 100vw;
    font-size: 0.9em;
    background-color: #ffffff;
    max-height: 60px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    padding: 8px 18px;
    z-index: 1;
    border-bottom: 1px solid #F0F0F0;

    #headerLogoCol {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
    }
  }

    #headerLogoCol {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 100%;
      max-width: 4.5%;
    }

  .App-logo {
    height: 100%;
    margin: 1.5rem;
  }
}

#StatsSaApp {
  #headerContainer {
    font-size: 0.9em;
    background-color: white;
    display: flex;
    align-items: center;
    height: 80px;
    padding: 10px;
    border: 1px solid lightgray;
  }

  .App-logo {
    height: 100%;
  }
}

#BedworthApp {
  .logoHeaderWithNav {
    img {
      height: 80px;
      width: unset;
    }
  }

  .App-logo {
    height: 80px;
  }
}

#FidelitySFApp {
  .logoHeaderWithNav {
    a,
    span {
      color: #595959 !important;
    }

    flex-wrap: nowrap !important;

    .link-item {
      margin: 0 20px 0 20px;
      color: #ff1111 !important;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    #header {
      &LogoCol {
        margin-right: 2em;

        img {
          height: 35px;
          width: 42px;
        }
      }

      &Links {
        .outerLinkContainer {
          height: 90%;
          margin-top: -1.25em;
          margin: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: fit-content;
          @media screen and (min-width: $md) {
            max-width: 12vw;
          }
          .innerLinkContainer {
            text-align: center;
          }
        }
      }
    }

    .headerLinksText {
      white-space: normal;
    }

    .dropdown-toggle::after {
      content: none !important;
    }

    .modal-body {
      text-align: center;
    }
  }

  #forcelinkFooter {
    .navbar-brand {
      height: 100%;
    }
    height: 60px;
    @media screen and (max-width: $md) {
      background-color: #f8f9fa !important;
    }
  }

  #headerLinks.showDropdown {
    background-color: adjust-color($color: $SSGFacilitiesColor, $lightness: 5%);
  }

  .logoHeaderWithNav,
  #headerContainer {
    width: 100vw;
    font-size: 0.9em;
    background-color: #ffffff;
    max-height: 60px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    padding: 8px 18px;
    z-index: 1;
    border-bottom: 1px solid #F0F0F0;

    #headerLogoCol {
      height: 100%;
      justify-content: center;
      align-items: center;
      margin: 0px;
    }
  }

  .App-logo {
    height: 100%;
    margin: 1.5rem;
  }
}
