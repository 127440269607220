#FidelitySFApp {
  .iconRow {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .ui.link.card {
    height: 140px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    box-shadow: none;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .header {
      font-size: large;
      color: #262626;
    }

    svg {
      color: #262626;
    }
  }
}
